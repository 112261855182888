.plans_checkout {


    .content-area {
        height: 100vh;
        width: 100%;
        text-align: center;
    }

    .d-flex {
        display: flex;
    }

    .align-items-center {
        align-items: center;
    }

    .justify-content-center {
        justify-content: center;
    }

    /* make keyframes that tell the start state and the end state of our object */
    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .fade-in {
        opacity: 0;
        /* make things invisible upon start */
        -webkit-animation: fadeIn ease-in 1;
        /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
        -moz-animation: fadeIn ease-in 1;
        animation: fadeIn ease-in 1;

        -webkit-animation-fill-mode: forwards;
        /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        animation-duration: 1s;

        &.one {
            -webkit-animation-delay: 0.7s;
            -moz-animation-delay: 0.7s;
            animation-delay: 0.7s;
        }

        &.two {
            -webkit-animation-delay: 1.2s;
            -moz-animation-delay: 1.2s;
            animation-delay: 1.2s;
        }

        &.three {
            -webkit-animation-delay: 1.7s;
            -moz-animation-delay: 1.7s;
            animation-delay: 1.7s;
        }
    }

    .logo {
        max-width: 480px;
        width: 80%;
        display: block;
        margin: 0 auto;
    }

    h3 {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
            "Courier New", monospace;
        letter-spacing: 5px;
        text-transform: uppercase;
        font-weight: 100;
        color: rgba(#000, 0.8);
    }

    .social {
        ul {
            list-style-type: none;
            padding: 0;

            li {
                display: inline-block;
                margin: 5px;

                a {
                    img {
                        width: 28px;

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }

    .back_button {
        button {
            padding: 8px 20px;
            font-size: 15px;
            font-weight: 500;
            color: #ffffff;
            background-color: #4285f4;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }

        button:hover {
            background-color: #0d66c2;
        }
    }

    .copy-right {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        text-align: center;
        padding-bottom: 15px;
        font-size: 12px;
        color: rgba(#000, 0.5);
    }
}