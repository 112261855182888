@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin hideScrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}
