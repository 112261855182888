.button-primary-component {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem !important;
  height: 2.5rem;
  width: fit-content;
  border-radius: 5px !important;
  cursor: pointer;
  background-color: $color-primary-2 !important;
  color: $color-light !important;
  transition: $transition-1;
  user-select: none;
  border: 2px solid $color-primary-2 !important;

  svg {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }

  p {
    font-size: 0.8rem;
    white-space: nowrap;
    margin: 0;
  }

  &:hover {
    background-color: $color-primary-3 !important;
    border-color: $color-primary-3 !important;
  }
}

.button-primary-comp.outlined {
  background-color: transparent;

  svg,
  p {
    color: $color-primary-2;
  }

  &:hover {
    background-color: $color-primary-2;
    border-color: $color-primary-2;

    svg,
    p {
      color: $color-light;
    }
  }
}