.top-panel {
  height: 4rem;
  //   background-color: red;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem 0 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @import "./popup-invite";

  .left-panel {
    .logo {
      width: 3rem;
      cursor: pointer;

      img {
        width: 100%;
      }

      @media screen and (max-width: 915px) and (max-height: 414px) {
        display: none;
      }

      @media screen and (max-width: 414px) {
        display: none;
      }
    }
  }

  .right-panel {
    display: flex;
    align-items: center;

    .button-primary-component {
      margin-right: 2rem;
      padding: 0.5rem 1rem;

      @media screen and (max-width: 896px) and (max-height: 414px) {
        margin-right: 0.5rem;
        padding: 0.25rem 0.5rem;
      }

      @media screen and (max-width: 414px) {
        margin-right: 0.5rem;
        padding: 0.25rem 0.5rem;
      }
    }

    .avatars {
      margin-right: 2rem;
      display: flex;
      align-items: center;
      background-color: $color-light;
      padding: 0.5rem 0 0.5rem 1.75rem;
      box-shadow: $shadow-2;
      border-radius: 10px;

      @media screen and (max-width: 896px) and (max-height: 414px) {
        margin-right: 0.5rem;
        padding: 0.35rem 0 0.35rem 0.75rem;
        width: 153px;
      }

      @media screen and (max-width: 414px) {
        margin-right: 0.5rem;
        padding: 0.35rem 0 0.35rem 0.75rem;
        width: 153px;
      }

      .avatar {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        // cursor: pointer;
        border: 2px solid $color-light;

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            transform: translateX(#{$i * -5px});

            &:hover {
              transform: translateX(#{$i * -5px}) scale(1.1);
              z-index: 1;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .more {
      width: 3rem;
      height: 3rem;
      background-color: $color-light;
      @include flexCenter;
      border-radius: 10px;
      box-shadow: $shadow-2;
      cursor: pointer;

      &:hover {
        background-color: $color-primary-1;
        color: $color-light;
      }

      @media screen and (max-width: 896px) and (max-height: 414px) {
        width: 2.7rem;
        height: 2.7rem;
      }

      @media screen and (max-width: 414px) {
        width: 2.7rem;
        height: 2.7rem;
      }
    }
  }
}