.input-text {
  .label {
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
    color: $color-grey-6;
    margin-left: 0.2rem;
  }
  .input {
    height: 2.5rem;
    border: 2px solid rgba($color-grey-5, 0.5);
    border-radius: 5px;
    width: 100%;
    transition: $transition-1;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0 0.5rem 0 1rem;
      font-family: inherit;
      font-size: 0.8rem;

      &::placeholder {
        color: $color-grey-4;
      }
    }

    .eye-icon {
      padding: 0 0.5rem;
      svg {
        color: $color-grey-5;
        cursor: pointer;
      }
    }
  }

  &:hover {
    .input {
      border-color: $color-primary-1;
    }
  }
}
.input-primary.focused {
  .input {
    border-color: $color-primary-3;
  }
}
.input-primary.input-disabled {
  .input {
    background-color: rgba($color-grey-5, 0.1);
    border-color: rgba($color-grey-5, 0.3);
    &:hover {
      border-color: rgba($color-grey-5, 0.3);
    }
  }
}
