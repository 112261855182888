.card-primary {
  width: 15rem;
  overflow: hidden;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
  transition: $transition-2;
  background-color: $color-light;

  .icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    @include flexCenter;
    cursor: pointer;
    transition: $transition-1;

    svg {
      font-size: 1.2rem;
      color: rgba($color-grey-4, 1);
    }

    &:hover {
      background-color: rgba($color-grey-3, 0.4);
    }
  }

  .img {
    width: 15rem;
    height: 10rem;
    position: relative;
    overflow: hidden;

    .img-spinner {
      position: absolute;
      @include absCenter;
      transform: translate(-50%, -50%) !important;
    }

    .bg-card-dark {
      position: absolute;
      inset: 0;
      background-color: rgba($color-dark, 0.2);
      transition: $transition-2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $transition-2;
    }

    &:hover {
      .bg-card-dark {
        background-color: rgba($color-dark, 0.4);
      }

      img {
        transform: scale(1.1);
      }
    }

    .icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;

      svg {
        color: $color-light;
      }
    }
  }

  .desc {
    padding: 0.5rem 0.5rem 1rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      .name {
        margin-bottom: 0.2rem;
      }

      .modified {
        color: $color-grey-5;
        font-size: 0.8rem;
        margin-bottom: 0;
      }
    }


  }

  .avatars {
    // margin-right: 2rem;
    display: flex;
    align-items: center;
    // background-color: $color-light;
    padding: 0.5rem 0 0.5rem 1.75rem;
    // box-shadow: $shadow-2;
    // border-radius: 10px;

    .avatar {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      // cursor: pointer;
      border: 2px solid $color-light;

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          transform: translateX(#{$i * -5px});

          &:hover {
            transform: translateX(#{$i * -5px}) scale(1.1);
            z-index: 1;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &:hover {
    box-shadow: $shadow-1;
  }
}