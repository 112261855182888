.input-select {
  width: 100%;
  position: relative;
  user-select: none;

  .bg {
    position: fixed;
    inset: 0;
  }

  p {
    margin-bottom: 0;
    font-size: 0.8rem;
    margin-top: 0;
  }

  .label {
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
    color: $color-grey-6;
    margin-left: 0.2rem;
  }

  .select-btn {
    width: 100%;
    height: 2.5rem;

    background-color: $color-light;
    border-radius: 5px;
    border: 2px solid rgba($color-grey-5, 0.5);
    padding: 0 1rem;
    transition: $transition-1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .btn-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 0.2rem;

      p {
        font-size: 0.8rem;
        color: $color-grey-4;
      }
    }

    .btn-cont.selected {
      p {
        color: $color-dark;
      }
    }

    &:hover {
      border-color: $color-primary-1;
    }

    svg {
      font-size: 1.2rem;
    }
  }

  .select-btn.active {
    border-color: $color-primary-2;
  }

  .list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $color-light;
    box-shadow: $shadow-1;
    max-height: 10rem;
    overflow-y: auto;

    .item {
      padding: 0.5rem 1rem;
      transition: $transition-1;
      cursor: pointer;

      &:hover {
        background-color: $color-primary-2;
        color: $color-light;
      }
    }
  }
}