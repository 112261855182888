.permission-cont {
    position: fixed;
  
    .background-dark {
      position: fixed;
      inset: 0;
      background-color: rgba($color-dark, 0.5);
    }
  
    .cont {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-width: 20rem;
      width: fit-content;
      height: fit-content;
      // min-height: 50vh;
      background-color: $color-light;
      padding: 2rem;
      color: $color-dark;
      border-radius: 10px;
      h3 {
        margin-bottom: 1rem;
      }
      .flex {
        display: flex;
        justify-content: center;
        .button-primary-component {
          &:first-child {
            margin-right: 2rem;
          }
          &:nth-child(2) {
            background-color: $color-danger-2 !important;
            border-color: $color-danger-2 !important;
            &:hover {
              background-color: $color-danger-3 !important;
              border-color: $color-danger-3 !important;
            }
          }
        }
      }
    }
  }
  