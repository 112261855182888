.input-multi-select {
  overflow: hidden;

  .label {
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
    color: $color-grey-6;
    margin-left: 0.2rem;
  }

  .input {
    height: 2.5rem;
    border: 2px solid rgba($color-grey-5, 0.5);
    border-radius: 5px;
    width: 100%;
    transition: $transition-1;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0 0.5rem 0 1rem;
      font-family: inherit;
      font-size: 0.8rem;

      &::placeholder {
        color: $color-grey-4;
      }
    }

    input.notvalidated {
      text-decoration: underline;
      text-decoration-color: $color-danger-2;
    }

    .btn {
      font-weight: bold;
      color: $color-primary-1;
      transition: $transition-1;
      width: fit-content;
      font-size: 0.8rem;
      cursor: pointer;
      margin: 5px;

      &:hover {
        color: $color-primary-2;
        background-color: rgba($color-primary-1, 0.2);
      }
    }
  }

  &:hover {
    .input {
      border-color: $color-primary-1;
    }
  }

  .chip-cont {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    flex-wrap: wrap;

    .chip {
      white-space: nowrap;
      font-size: 0.75rem;
      padding: 0.3rem 0.6rem;
      background-color: rgba($color-primary-1, 0.2);
      display: flex;
      align-items: center;
      border-radius: 15px;
      margin-bottom: 0.5rem;
      font-weight: bold;

      p {
        margin-right: 0.5rem;
      }

      svg {
        font-size: 0.9rem;
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}

.input-primary.focused {
  .input {
    border-color: $color-primary-3;
  }
}

.input-primary.input-disabled {
  .input {
    background-color: rgba($color-grey-5, 0.1);
    border-color: rgba($color-grey-5, 0.3);

    &:hover {
      border-color: rgba($color-grey-5, 0.3);
    }
  }
}