.popup-invite {
  position: fixed;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: $transition-1;

  .bg-dark-email {
    position: fixed;
    inset: 0;
    background-color: rgba($color-dark, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: $transition-1;
  }

  .popup-content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);
    background-color: $color-light;
    padding: 3rem;
    border-radius: 10px;
    width: 27rem;
    height: fit-content;
    transition: $transition-1;

    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 2rem;
      height: 2rem;
      background-color: $color-secondary-2;
      @include flexCenter;
      border-radius: 10px;
      color: $color-light;
      cursor: pointer;
      transition: $transition-1;

      svg {
        font-size: 1rem;
      }

      &:hover {
        background-color: $color-secondary-3;
      }
    }

    .link-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5rem;
      border: 2px solid rgba($color-grey-5, 0.5);
      border-radius: 5px;
      margin-bottom: 2rem;
      height: 2.5rem;

      p {
        margin: 0;
        color: $color-grey-5;
        font-size: 0.8rem;
      }

      .copy-icon {
        svg {
          cursor: pointer;
          color: $color-grey-5;
          font-size: 1.2rem;
        }
      }
    }

    .cont-title {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      color: $color-grey-6;
    }

    .desc {
      margin-bottom: 1.5rem;
      font-size: 0.8rem;

      span {
        font-weight: bold;
      }
    }

    .input-cont {
      display: flex;
      align-items: center;

      .input-text {
        flex: 1;
        margin-bottom: 1.5rem;
      }

      .icon {
        margin-left: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        background-color: rgba($color-grey-5, 0.1);
        border-radius: 50%;
        @include flexCenter;
        cursor: pointer;

        &:hover {
          background-color: $color-primary-1;
          color: $color-light;
        }
      }
    }
  }
}

.popup-invite.visible {
  visibility: visible;
  opacity: 1;

  .bg-dark-email {
    visibility: visible;
    opacity: 1;
  }

  .popup-content {
    transform: translate(-50%, -50%);
  }
}