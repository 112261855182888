// Margin

// Margin top

.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}

// Margin bottom

.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}
.mb-6 {
  margin-bottom: 6rem !important;
}

// Margin left

.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.ml-4 {
  margin-left: 4rem !important;
}
.ml-5 {
  margin-left: 5rem !important;
}
.ml-6 {
  margin-left: 6rem !important;
}

// Margin right

.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-3 {
  margin-right: 3rem !important;
}
.mr-4 {
  margin-right: 4rem !important;
}
.mr-5 {
  margin-right: 5rem !important;
}
.mr-6 {
  margin-right: 6rem !important;
}

// Padding

// Padding top

.pt-1 {
  padding-top: 1rem !important;
}
.pt-2 {
  padding-top: 2rem !important;
}
.pt-3 {
  padding-top: 3rem !important;
}
.pt-4 {
  padding-top: 4rem !important;
}
.pt-5 {
  padding-top: 5rem !important;
}
.pt-6 {
  padding-top: 6rem !important;
}

// Padding bottom

.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-2 {
  padding-bottom: 2rem !important;
}
.pb-3 {
  padding-bottom: 3rem !important;
}
.pb-4 {
  padding-bottom: 4rem !important;
}
.pb-5 {
  padding-bottom: 5rem !important;
}
.pb-6 {
  padding-bottom: 6rem !important;
}

// Padding left

.pl-1 {
  padding-left: 1rem !important;
}
.pl-2 {
  padding-left: 2rem !important;
}
.pl-3 {
  padding-left: 3rem !important;
}
.pl-4 {
  padding-left: 4rem !important;
}
.pl-5 {
  padding-left: 5rem !important;
}
.pl-6 {
  padding-left: 6rem !important;
}

// Padding right

.pr-1 {
  padding-right: 1rem !important;
}
.pr-2 {
  padding-right: 2rem !important;
}
.pr-3 {
  padding-right: 3rem !important;
}
.pr-4 {
  padding-right: 4rem !important;
}
.pr-5 {
  padding-right: 5rem !important;
}
.pr-6 {
  padding-right: 6rem !important;
}

// Hide on mobile

@include respond(phone) {
  .hd-mb {
    display: none !important;
  }

  .hv-mb {
    visibility: hidden !important;
    opacity: 0 !important;
  }
}

// Hide on desktop and tabs

.hd-dstb {
  display: none !important;
}

.hv-dstb {
  visibility: hidden !important;
  opacity: 0 !important;
}

@include respond(phone) {
  .hd-dstb {
    display: inline-block !important;
  }

  .hv-dstb {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

// Color
.green {
  color: var(--color-success) !important;
}

.red {
  color: var(--color-danger) !important;
}

.theme {
  background-color: var(--color-light);
  color: var(--color-dark);
}
