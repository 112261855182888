.popup-password {
  position: fixed;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: $transition-1;

  .bg-dark-password {
    position: fixed;
    inset: 0;
    background-color: rgba($color-dark, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: $transition-1;
  }

  .popup-content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);
    background-color: $color-light;
    padding: 3rem;
    border-radius: 10px;
    min-width: 28rem;
    height: fit-content;
    transition: $transition-1;

    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 2rem;
      height: 2rem;
      background-color: $color-secondary-2;
      @include flexCenter;
      border-radius: 10px;
      color: $color-light;
      cursor: pointer;
      transition: $transition-1;

      svg {
        font-size: 1rem;
      }

      &:hover {
        background-color: $color-secondary-3;
      }
    }

    .cont-title {
      font-weight: bold;
      font-size: 1.3rem;
      //   margin-bottom: 0.5rem;
      color: $color-grey-6;
      margin-bottom: 1rem;
    }

    .desc {
      margin-bottom: 1rem;
      font-size: 0.8rem;

      span {
        font-weight: bold;
      }
    }

    .input-text {
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 470px) {
      min-width: 23rem;
    }

    @media screen and (max-width: 400px) {
      min-width: 21rem;
    }
  }
}

.popup-password.visible {
  visibility: visible;
  opacity: 1;

  .bg-dark-password {
    visibility: visible;
    opacity: 1;
  }

  .popup-content {
    transform: translate(-50%, -50%);
  }
}