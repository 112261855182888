.navbar-dash {
  position: fixed;
  top: 0;
  left: 15rem;
  right: 0;
  height: 5rem;
  background-color: $color-background-1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  z-index: 998;
  //   box-shadow: $shadow-1;

  .left {
    .input {
      display: flex;
      align-items: center;
      background-color: $color-light;
      height: 3rem;
      width: 20rem;
      padding: 0 0.8rem;
      border: 1px solid rgba($color-grey-3, 0.5);
      border-radius: 5px;
      @media screen and (max-width: 820px){
        width: 16rem !important;
      }

      @media screen and (max-width: 768px){
        width: 14rem !important;
      }

      @media screen and (max-width: 540px){
        width: 20rem !important;
      }

      @media screen and (max-width: 420px){
        width: 13rem !important;
      }

      @media screen and (max-width: 420px){
        width: 11rem !important;
      }

      svg {
        font-size: 1.2rem;
        margin-right: 0.8rem;
      }

      input {
        border: none;
        outline: none;
        background-color: transparent;
        width: 100%;
        height: 100%;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .icon {
      width: 2.5rem;
      height: 2.5rem;
      background-color: rgba($color-grey-3, 0.4);
      border-radius: 50%;
      @include flexCenter;
      cursor: pointer;
      transition: $transition-1;

      svg {
        font-size: 1.2rem;
        color: rgba($color-grey-6, 1);
      }

      &:hover {
        background-color: rgba($color-grey-3, 0.8);
      }
    }

    .button-primary-component {
      margin-right: 2rem;
      background-color: $color-success-2 !important
      ;
      border-color: $color-success-2 !important;

      &:first-child {
        border-radius: 20px !important;
      }

      @media screen and (max-width: 820px){
        margin-right: 6rem !important;
      }

      @media screen and (max-width: 768px){
        margin-right: 5rem !important;
      }
    }

    .avatar {
      width: 2.5rem;
      height: 2.5rem;
      background-color: rgba($color-secondary-2, 1);
      color: $color-light;
      border-radius: 50%;
      @media screen and (max-width: 820px){
        margin-top: 10px;
      }  

      @include flexCenter;
      p {
        margin-bottom: 0;
        font-size: 1.2rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@include respond(phone) {
  .navbar-dash {
    left: 0;
    padding: 1rem 1rem 1rem 5rem;
    .left {
      margin-right: 1rem;
      flex: 1;
      .input {
        width: 100%;
      }
    }
    .right {
      .button-primary-component {
        display: none;
        p {
          display: none;
        }
        svg {
          margin: 0;
        }
      }
    }
  }
}
