.profile-page {
  min-height: 100vh;
  background-color: $color-background-1;
  @import "./popup-email";
  @import "./popup-password";

  .profile-main {
    padding-left: 15rem;
    padding-top: 5rem;
    padding: 6rem 3rem 5rem 18rem;

    .section {
      h1 {
        margin-bottom: 2rem;
        color: $color-grey-6;
        font-size: 1.8rem;
      }

      .profile {
        .label-photo {
          font-weight: bold;
          font-size: 0.8rem;
          margin-bottom: 0.3rem;
          color: $color-grey-6;
          margin-left: 0.2rem;
        }

        .file {
          position: absolute;
          visibility: hidden;
        }

        .img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          margin-right: 5rem;
          position: relative;
          cursor: pointer;
          margin-bottom: 2rem;

          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
          }

          .info {
            position: absolute;
            inset: 0;
            background-color: rgba($color-dark, 0.5);
            visibility: hidden;
            opacity: 0;
            transition: $transition-1;
            border-radius: 10px;

            p {
              text-align: center;
              @include absCenter;
              color: $color-light;
              text-transform: uppercase;
              user-select: none;
              font-size: 0.8rem;
            }
          }

          .add-icon {
            position: absolute;
            margin-left: 5rem;
            left: -0.75rem;
            bottom: -0.75rem;
            color: $color-light;
            width: 2rem;
            height: 2rem;
            background-color: $color-secondary-2;
            border-radius: 50%;
            z-index: 1;
            @include flexCenter;
          }

          &:hover {
            .info {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      .container {
        background-color: $color-light;
        padding: 2rem;
        border-radius: 10px;
        box-shadow: $shadow-1;

        .grid-cont {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 2rem;
          margin-bottom: 2rem;
        }

        .card-container {
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 2px solid rgba($color-grey-5, 0.1);
          border-radius: 10px;

          &:not(:last-child) {
            margin-bottom: 2rem;
          }

          .left {
            .label {
              font-weight: bold;
              font-size: 0.8rem;
              margin-bottom: 0.3rem;
              color: $color-grey-6;
            }

            p {
              margin-bottom: 0;
              font-size: 0.8rem;

              span {
                font-weight: bold;
              }
            }
          }

          .btn-text {
            font-weight: bold;
            color: $color-primary-2;
            font-size: 0.9rem;
            cursor: pointer;
            user-select: none;
            transition: $transition-1;

            &:hover {
              color: $color-primary-1;
            }
          }

          .role_margin {
            margin-top: 8px;
          }
        }
      }
    }
  }
}

@include respond(phone) {
  .profile-page {
    .profile-main {
      padding: 6rem 2rem 5rem 2rem;

      .section {
        .container {
          .grid-cont {
            grid-template-columns: repeat(1, 1fr);
          }

          .card-container {
            .btn-text {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}