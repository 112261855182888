.dashboard-page {
  min-height: 100vh;
  background-color: $color-background-1;

  .dashboard-main {
    padding-left: 15rem;
    padding-top: 5rem;
    padding: 6rem 3rem 5rem 18rem;

    .tabs-section .canvas-boards-section {
      padding-top: 20px;
    }

    .tabs-section .card-grid {
      display: grid;
      padding-top: 40px;

      .grid-child {
        padding: 15px 0;
      }

      @media screen and (max-width: 1365px) {
        grid-template-columns: 4fr 4fr 4fr;
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 6fr 6fr;
      }

      @media screen and (max-width: 539px) {
        grid-template-columns: 12fr;
      }

      @media screen and (min-width: 1366px) {
        grid-template-columns: 3fr 3fr 3fr 3fr;
      }

      @media screen and (min-width: 1517px) {
        grid-template-columns: 3fr 3fr 3fr 3fr;
      }
    }

    .spinner-container {
      height: calc(100vh - 11rem);
      @include flexCenter;

      .spinner {
        margin-bottom: 1rem;
        color: $color-primary-2;
      }

      p {
        color: $color-grey-5;
      }
    }

    .empty-canvas {
      height: calc(50vh - 11rem);
      @include flexCenter;

      p {
        color: $color-grey-5;
      }
    }

    .section {
      .templates {
        padding: 1rem;
        background-color: $color-light;
        margin-bottom: 2rem;
        border-radius: 10px;
        width: fit-content;
        display: flex;
        align-items: center;

        .template {
          &:not(:last-child) {
            margin-right: 1rem;
          }

          .btn-create {
            padding: 1.5rem 3rem;
            background-color: rgba($color-primary-2, 0.1);
            border-radius: 10px;
            transition: $transition-1;
            width: fit-content;
            cursor: pointer;
            margin-bottom: 0.5rem;

            &:hover {
              background-color: $color-primary-2;
              color: $color-light;
            }
          }

          p {
            text-align: center;
            margin: 0;
            color: $color-grey-6;
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
      }

      h1 {
        margin-bottom: 2rem;
        color: $color-grey-6;
        font-size: 1.8rem;
      }

      .flex-cont {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        gap: 2rem;
      }
    }
  }
}

@include respond(phone) {
  .dashboard-page {
    .dashboard-main {
      padding: 6rem 2rem 5rem 2rem;

      .section {
        .templates {
          margin: 0 auto;
          margin-bottom: 2rem;
        }

        .flex-cont {
          justify-content: center;
        }
      }
    }
  }
}