@font-face {
  font-family: 'Recursive';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/recursive/v23/8vI-7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUlTGZnI14ZeY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Recursive';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/recursive/v23/8vI-7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUlTGZnI14ZeY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Recursive Mono';
  font-style: normal;
  font-weight: 420;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/recursive/v23/8vI-7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImqvTxvU-MXGX2fSqasNfUlTGZnI14ZeY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.tldraw {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
}

#language-menu {
  margin-left: 8px;
}


.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 992px) {
  .col-1g-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.d-none {
  display: none;
}

/* --- 100ms video styles --- */

.video-maxwidth {
  width: 260px !important;
}

.video-minwidth {
  width: 25px !important;
}

@media screen and (max-width: 896px) and (max-height: 414px) {
  .video-maxwidth {
    width: 170px !important;
  }

  .video-minwidth {
    width: 20px !important;
  }

  .col-10 {
    flex: 0 0 auto;
    width: 80%;
  }

  .col-2 {
    flex: 0 0 auto;
    width: 20%;
  }

  .col-1g-10 {
    flex: 0 0 auto;
    width: 80%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 20%;
  }
}

@media screen and (max-width: 414px) {
  .video-maxwidth {
    width: 170px !important;
  }

  .video-minwidth {
    width: 20px !important;
  }

  .col-10 {
    flex: 0 0 auto;
    width: 80%;
  }

  .col-2 {
    flex: 0 0 auto;
    width: 20%;
  }

  .col-1g-10 {
    flex: 0 0 auto;
    width: 80%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 20%;
  }
}