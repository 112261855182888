.reciever-preview {
  position: fixed;

  .background-dark {
    position: fixed;
    inset: 0;
    background-color: rgba($color-dark, 0.5);
  }

  .preview-cont {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // min-width: 50vw;
    // width: fit-content;
    // height: fit-content;
    background-color: $color-light;
    padding: 2rem;
    display: grid;
    grid-template-columns: 3fr 5fr 3fr;
    color: $color-dark;
    gap: 2rem;
    border-radius: 10px;

    .video {
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: #ffffff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 30%;
        top: 40%;
        position: absolute;
      }

      video {
        min-height: 9.5rem;
        min-width: 15rem;
      }
    }


    @media screen and (max-width: 1024px) and (min-width: 769px) {
      grid-template-columns: repeat(2, 1fr);
      width: 590px;

      .left-preview {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        z-index: 2;
        text-align: center;

        .logo {
          display: flex;
          justify-content: center;

          img {
            max-width: 300px;
            max-height: 70px;
          }
        }
      }

      .video {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
        z-index: 1;
      }

      .actions {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
        z-index: 1;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 12fr;
      max-width: 390px;

      .left-preview {
        text-align: center;
      }
    }

    @media screen and (max-width: 540px) {
      grid-template-columns: 12fr;
      width: 330px;

      .left-preview {
        text-align: center;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .video-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .button-primary-component {
          background-color: rgba($color-grey-6, 0.2) !important;
          border: none !important;
          width: 180px;
          margin: 8px 0;

          p,
          svg {
            color: $color-dark !important;
          }

          &:hover {
            background-color: rgba($color-grey-6, 0.3) !important;
            border: none !important;
          }
        }
      }

      .user-actions {
        display: flex;
        margin: 15px 0;
        justify-content: space-between;

        .button-primary-component {
          border: none !important;
          margin-bottom: 0;

          &:first-child {
            background-color: $color-danger-2 !important;
            margin-right: 1.5rem;

            &:hover {
              background-color: $color-danger-3 !important;
            }
          }
        }

        @media screen and (max-width: 1024px) {
          justify-content: center;
        }
      }
    }
  }
}