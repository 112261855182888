.navbar-home-page-avatar {
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba($color-secondary-2, 1);
    color: $color-light;
    border-radius: 50%;
    @include flexCenter;

    @media screen and (max-width: 912px) {
        width: 75%;
        height: 75%;
    }

    &:hover {
        background-color: $color-secondary-2;
    }

    p {
        margin-bottom: 0;
        font-size: 1.2rem;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}

.dashboard-menu {
    margin-top: -4px !important;

    @media screen and (max-width: 912px) {
        margin-top: -13px !important;
    }
}

.menu-small-device-ui {
    margin-top: 11px !important;
}