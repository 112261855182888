.sidebar-open {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15rem;
    padding: 1rem;
    background-color: $color-light;
    box-shadow: $shadow-1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 999;
    transition: $transition-1;

    .logo {
      width: fit-content;
      margin: 1.5rem auto 2.5rem;
      cursor: pointer;
      width: 10rem;

      img {
        width: 100%;
      }
    }

    .menu {
      height: 100%;

      .section {
        margin-bottom: 2rem;

        .tab {
          height: 2.8rem;
          border-radius: 5px;
          display: flex;
          align-items: center;
          margin-bottom: 0.8rem;
          cursor: pointer;
          transition: $transition-1;

          &:hover {
            background-color: rgba($color-primary-2, 0.1);
            color: $color-dark;
          }

          .icon {
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .dot {
              position: absolute;
              top: 0.5rem;
              right: 0.5rem;
              width: 0.5rem;
              height: 0.5rem;
              background-color: $color-primary-2;
              border-radius: 50%;
            }

            svg {
              font-size: 1.2rem;
            }
          }

          p {
            font-size: 0.8rem;
            margin-bottom: 0;
            margin-top: -3px;
          }
        }

        .tab-active {
          background-color: $color-primary-2 !important;

          .icon {
            .dot {
              display: none;
            }
          }

          p,
          svg {
            color: $color-light !important;
          }
        }
      }
    }
  }
}

.sidebar-open-btn {
  position: fixed;
  top: 1.2rem;
  left: 1rem;
  z-index: 999;
  width: 2.5rem;
  height: 2.5rem;
  background-color: $color-primary-2;
  @include flexCenter;
  color: $color-light;
  border-radius: 5px;
  cursor: pointer;
  display: none;
  z-index: 999;
}

@include respond(phone) {
  .sidebar-open-btn {
    display: flex;
  }

  .sidebar-open {
    .bg-dark-mob {
      position: fixed;
      inset: 0;
      background-color: rgba($color-dark, 0.5);
      visibility: hidden;
      opacity: 0;
      z-index: 999;
      transition: $transition-1;
    }

    .wrapper {
      transform: translateX(-16rem);
    }
  }

  .sidebar-open.sidebar-active {
    .bg-dark-mob {
      visibility: visible;
      opacity: 1;
    }

    .wrapper {
      transform: translateX(0);
    }
  }

  .main {
    padding-left: 0 !important;
  }
}